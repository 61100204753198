import React, {useEffect, useState} from 'react';
import PartyCard from "./partyCard";
import Timer3 from "../assetComponents/countdown";
import flyer from '../../../assets/flyer.jpg'
import axios from "axios";

export default function MainHome(){

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://admin.halle-hoechst.de/api/info',
        };

        axios.request(config)
            .then((response) => {
                setData(response.data);
                setLoading(false); // set loading to false when data is fetched
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    return(
        <div className='flex flex-col md:px-28 gap-12 px-8'>
            <div className='text-center pt-12'>
                <h1 className='text-gray-200 md:text-[30px] lg:text-[43px] text-[23px] pb-8'>Herzlich Willkommen auf der Homepage des Freizeitclubs "Die Halle Höchst"</h1>
            </div>
            <div className='flex flex-col justify-center items-center md:px-28 gap-12 px-2'>
                <img src="https://admin.xn--halle-hchst-xfb.de/uploads/homeimage.webp" className='rounded shadow-xl' alt='flyer'/>
            </div>
            {data && data[0] && data[0].text &&
                <PartyCard data={data} state={false}/>
            }
            {data && data[2] && data[2].text &&
                <Timer3 endDate={data[2].text}/>
            }
            <div className='flex text-gray-200 lg:mx-48 md:mx-12 mx-0 flex-col justify-center items-center text-center gap-12 mb-24 mt-16'>
                {data && data[1] && data[1].text &&
                    <div dangerouslySetInnerHTML={{ __html: data[1].text }} />
                }
            </div>
        </div>
    )
}